//Base Url
export const Base_Url = process.env.REACT_APP_BASE_URL;


console.log("jdhjdhjlkdk",Base_Url)

export const Addwarehouse='createwarehouse'
// ware house list 
export const Warehouselist='warehouseslist'
//price estimetor
export const priceEstimator='priceEstimator'
// create shippment
export const createShippment='createShipment'

export const reverseShipment = 'reverseShipment'

export const forwardorderlist ='forwardorderlist'

export const shipmentlabel = 'shipmentlabel'

export const  pickupRequest = 'pickupRequest'

export const track = 'track'

export const csvuplode = 'csvuplode'

export const  registerapi = 'register'

export const loginapi = 'login'

export const dashboard = 'dashboard'

export const getpincode = 'getpincode'


